<template>
    <div>
        <el-form :inline="true" v-if="hasAuth('sys:reasoncancellation:add')">
            <el-form-item>
                <el-button type="primary" @click="saveButton(null)">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>

            <el-table-column prop="reasonCancellation" align="center" label="取消原因">
            </el-table-column>
            <el-table-column prop="reasonCancellationType" align="center" label="取消原因类型" width='200'>
                <template slot-scope="{row}">
                    <span v-if="row.reasonCancellationType == 1">货主</span>
                    <span v-if="row.reasonCancellationType == 2">司机</span>
                </template>
            </el-table-column>

            <el-table-column prop="isRefund" align="center" label="是否退还订金" width='200'>
                <template slot-scope="{row}">
                    <span v-if="row.isRefund == 0">不退还</span>
                    <span v-if="row.isRefund == 1">退还</span>
                </template>
            </el-table-column>
            <el-table-column prop="icon" align="center" width="300" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="saveButton(scope.row.reasonCancellationId)"
                        v-if="hasAuth('sys:reasoncancellation:update')">编辑</el-button>
                    <template v-if="hasAuth('sys:reasoncancellation:delete')">
                        <el-popconfirm title="确定要删除该条数据吗？" @confirm="delHandle(scope.row.reasonCancellationId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <savereasoncancellation ref="savereasoncancellation" v-if="savereasoncancellationvisible"
            @refreshDataList="getreasoncancellation"></savereasoncancellation>
    </div>
</template>

<script>
import savereasoncancellation from "./updateReasonCancellation";
export default {
    name: "VehicletypeList",
    components: {
        savereasoncancellation
    },
    data() {
        return {
            tableData: [],
            savereasoncancellationvisible: false,
        }
    },
    created() {
        this.getreasoncancellation();
    },
    methods: {
        getreasoncancellation() {
            this.$axios.post("/admin/reasoncancellation/getReasonCancellationList").then(res => {
                this.tableData = res.data
            })
        },
        delHandle(id) {
            this.$axios.post("/admin/reasoncancellation/delReasonCancellation", { reasonCancellationId: id }).then(res => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.getreasoncancellation()
                    }
                });
            })
        },
        saveButton(reasonCancellationId) {
            this.savereasoncancellationvisible = true;
            this.$nextTick(() => {
                this.$refs.savereasoncancellation.init(reasonCancellationId);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

